import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { PermissionsProvider } from '@tshio/react-router-permissions';
import { Helmet } from 'react-helmet';
import AuthProvider from 'contexts/auth/AuthProvider';
import { useAuth } from 'contexts/auth/auth';
import Routes from 'components/Routes';
import './index.scss';
import { Role } from 'api/types/auth';

const authorizationStrategy = (currentRoles: string[], requirements: any) => {
  for (const role of requirements) {
    if (currentRoles.includes(role)) {
      return true;
    }
  }
  return false;
};

interface PermissionProps {
  children: React.ReactNode;
}

const Permissions: React.FC<PermissionProps> = ({ children }) => {
  const { role, email, name, stores, isMasquerading } = useAuth();
  const pixelId = process.env.REACT_APP_PIXEL_ID;

  useEffect(() => {
    const script = document.createElement('script');

    // @ts-ignore
    (window || {}).zEACLoaded = null;

    if (role === Role.HOST) {
      script.src =
        'https://static.zdassets.com/ekr/snippet.js?key=3226cc41-6722-4397-9824-214e1bde236c';
    } else {
      script.src =
        'https://static.zdassets.com/ekr/snippet.js?key=f58fc6d8-1697-4d35-9d24-ac5729d16a30';
    }
    script.id = 'ze-snippet';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [role]);

  return (
    <>
      {role === Role.HOST && (
        <Helmet>
          <script type="text/javascript">
            {`!function(e,t,r,n){if(!e[n]){for(var a=e[n]=[],i=["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],s=0;s<i.length;s++){var c=i[s];a[c]=a[c]||function(e){return function(){var t=Array.prototype.slice.call(arguments);a.push([e,t])}}(c)}a.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+r+"/"+n+".js";var p=t.getElementsByTagName("script")[0];p.parentNode.insertBefore(o,p)}}(window,document,"PyK0t6rENh7d8J5a","delighted");

            delighted.survey({
              email: '${email}', // email (we fill this in)
              name: '${name}',               // customer name (we fill this in)
              properties: {                       // extra context (optional)
                stores: '${stores}',             // number of stores they have
              },
            });
            `}
          </script>
        </Helmet>
      )}
      {!isMasquerading && pixelId && (
        <Helmet>
          <script type="text/javascript">
            {` 
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '${pixelId}');
              fbq('track', 'PageView');
          `}
          </script>
          <noscript>
            {`<img
                  height='1' width='1' style='display:none'
                  src='https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1'
                />`}
          </noscript>
        </Helmet>
      )}
      <PermissionsProvider permissions={[role]} authorizationStrategy={authorizationStrategy}>
        {children}
      </PermissionsProvider>
    </>
  );
};

function App() {
  return (
    <AuthProvider>
      <Permissions>
        <Router>
          <div className="app">
            <a id="_download-dummy" />
            <Routes />
          </div>
        </Router>
      </Permissions>
    </AuthProvider>
  );
}

export default App;
