import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import logoSvg from './logo.svg';

export default function Logo({ className, ...rest }) {
  return (
    <img className={classNames(className, 'Logo')} width={50} height={47} src={logoSvg} {...rest} />
  );
}

Logo.propTypes = {
  className: PropTypes.string,
};
