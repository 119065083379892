import { Store } from 'api/types/store';
import { createContext, useContext } from 'react';

interface Context extends Store {
  hasProducts: boolean;
}

export const StoreContext = createContext<Context | null>(null);
export const useStore = () => {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error('useStore must be used within a DashboardStoreProvider');
  }
  return context;
};
