/* eslint-disable react/prop-types */
import { useAuth } from 'contexts/auth/auth';
import React, { lazy } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { PartnerRoute } from './PartnerRoute';
import PartnerRoutes from './PartnerRoutes';

const StoreList = lazy(() =>
  import(
    /* webpackChunkName: "overview" */
    'components/StoreList'
  ),
);
const SettingsAccountPage = lazy(() =>
  import(
    /* webpackChunkName: "overview" */
    'pages/settings/Account'
  ),
);
const Payouts = lazy(() =>
  import(
    /* webpackChunkName: "overview" */
    'components/Dashboard/Payouts/'
  ),
);

const OverviewRoutes = () => {
  const { id: storeId, stores } = useAuth();
  const location = useLocation();

  if (!stores && location.pathname === '/overview/stores/') {
    return <Redirect to={{ pathname: '/onboarding', state: { stores, storeId } }} />;
  }

  return (
    <Switch>
      <Route path="/overview/stores/" component={StoreList} />
      <Route path="/overview/payouts/" component={Payouts} />
      <Route path="/overview/account/" component={SettingsAccountPage} />
      <PartnerRoute path="/overview/partners/">
        <PartnerRoutes />
      </PartnerRoute>
      <Route>
        <Redirect to="/overview/stores/" />
      </Route>
    </Switch>
  );
};

export default OverviewRoutes;
