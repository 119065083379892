import React, { useState, useEffect } from 'react';
import { message, Divider } from 'antd';
import { Button, Modal, SocialButton, TextField, Typography } from 'ui-kit';
import { Checkbox } from 'components/Form/Input';
import './SignUpPage.scss';
import { createOwner, socialLoginOrCreateOwner } from '../../api/auth';
import { useAuth } from '../../contexts/auth/auth';
import { useHistory } from 'react-router-dom';
import { getPromoCodeValidity } from '../../api/owners';
import Link from '../../ui-kit/Link';
import useSocialAuth from 'hooks/useSocialAuth';
import UnauthLayout from 'components/UnauthLayout';
import SignUpForm from './SignUpForm';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { signupResolver } from './utils';
import { Role } from 'api/types/auth';

const SignUpPage = () => {
  const { role } = useAuth();
  const history = useHistory();
  const [checked, setChecked] = useState(true);
  const [loading, setLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [hasAppliedPromoCode, setHasAppliedPromoCode] = useState(false);
  const { hostLogin } = useAuth();
  const { showSocialAuthMessage } = useSocialAuth();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      promoCode: '',
      firstName: '',
      email: '',
      password: '',
    },
    resolver: signupResolver,
  });

  const handleSubmit = () => {
    setModalIsOpen(true);
  };

  const create = () => {
    const { firstName, email, password, promoCode } = methods.getValues();
    setLoading(true);
    const payload = { firstName, email, password, promoCode: hasAppliedPromoCode ? promoCode : '' };
    createOwner(payload)
      .then((result) => {
        window.fbq?.(
          'trackCustom',
          'CreateAccount',
          { from: 'email', email: result?.user?.email },
          { eventID: result?.id },
        );
        if (hostLogin) {
          hostLogin(email, password);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (Object.keys(error.fields).length > 0) {
          const { email } = error.fields;
          if (email && email.includes('user with this email address already exists.')) {
            showSocialAuthMessage();
            return;
          }
        }
        if (error.nonFields.length > 0) {
          message.error(error.nonFields[0]);
        }
      });
  };

  const socialSubmit = (platform) => {
    if (hasAppliedPromoCode) {
      const { promoCode: promo } = methods.getValues();
      socialLoginOrCreateOwner(platform, { promo });
    } else {
      socialLoginOrCreateOwner(platform);
    }
  };

  const applyPromo = (event) => {
    event.preventDefault();
    const { promoCode } = methods.getValues();
    getPromoCodeValidity(promoCode)
      .then((res) => {
        if (res.ok) {
          if (res.data.isValid) {
            setHasAppliedPromoCode(true);
            message.success('Your promo code has been applied!');
          } else {
            setHasAppliedPromoCode(false);
            message.error('This is not a valid promo code. Please try again.');
          }
        } else {
          message.error('An error has occurred.');
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  useEffect(() => {
    if (role === Role.HOST) {
      history.push('/overview/stores/');
    }
  }, [role]);

  return (
    <UnauthLayout title="Sign up for your account">
      <FormProvider {...methods}>
        <div className="SignupPage-collapsable">
          {collapsed ? (
            <Button
              onClick={() => {
                setCollapsed(false);
              }}
            >
              Have a promo code?
            </Button>
          ) : (
            <div className="SignupPage-collapsable__content">
              <Controller
                control={methods.control}
                name="promoCode"
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    label="Have a promo code?"
                    placeholder="Promo Code..."
                    fullWidth
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
              <Button
                className="apply-button"
                variant="contained"
                onClick={applyPromo}
                size="small"
              >
                Apply
              </Button>
            </div>
          )}
        </div>
        <div className="SignupPage-social-login">
          <SocialButton
            social="google"
            label="Sign up with Google"
            fullWidth
            onClick={() => socialSubmit('google-oauth2')}
          />
          <SocialButton
            social="facebook"
            label=" Sign up with Facebook"
            fullWidth
            onClick={() => socialSubmit('facebook')}
          />
        </div>
        <Divider>
          <Typography variant="caption" color="textGray">
            OR
          </Typography>
        </Divider>
        <SignUpForm onSubmit={handleSubmit} />
      </FormProvider>
      <Typography variant="body2">
        Already have an account? &nbsp;
        <Button to="/login" className="SignupPage-singin-button">
          Sign in here
        </Button>
      </Typography>
      <Modal
        className="SignupPage-agreement-modal"
        visible={modalIsOpen}
        title="Complete signing up"
        onCancel={() => {
          setModalIsOpen(false);
        }}
        footer={
          <Button
            variant="contained"
            onClick={create}
            fullWidth
            disabled={!checked}
            loading={loading}
          >
            Continue to Dashboard
          </Button>
        }
      >
        <div className="SignupPage-agreement-modal__content">
          <Checkbox
            className="agreement-checkbox"
            checked={checked}
            onChange={() => {
              setChecked(!checked);
            }}
          />
          <p>
            By continuing, you agree to The Host Co.&nbsp;
            <Link href="https://www.thehost.co/terms-conditions">terms of service</Link>
            &nbsp;and&nbsp;
            <Link href="https://www.thehost.co/privacy-policy">privacy policy</Link>.
          </p>
        </div>
      </Modal>
    </UnauthLayout>
  );
};

export default SignUpPage;
