import React from 'react';
import { Row, Col } from 'antd';
import { Button, InputRules, TextField, TextFieldPassword } from 'ui-kit';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { getFieldError } from 'components/NewProductStepper/utils';

export interface InitialValues {
  firstName: string;
  email: string;
  password: string;
}

interface Props {
  initialValues?: InitialValues;
  onSubmit: (values: InitialValues) => Promise<void>;
}

export default function SignUpForm({ onSubmit }: Props) {
  const { control, handleSubmit, formState } = useFormContext();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row gutter={[0, 16]}>
        <Col xs={24}>
          <Controller
            control={control}
            name="firstName"
            render={({ field: { ref, ...rest }, fieldState }) => (
              <TextField
                label="First name"
                required
                fullWidth
                inputRef={ref}
                {...getFieldError(fieldState)}
                {...rest}
              />
            )}
          />
        </Col>
        <Col xs={24}>
          <Controller
            control={control}
            name="email"
            render={({ field: { ref, ...rest }, fieldState }) => (
              <TextField
                label="Email"
                required
                fullWidth
                inputRef={ref}
                {...getFieldError(fieldState)}
                {...rest}
              />
            )}
          />
        </Col>
        <Col xs={24}>
          <Controller
            control={control}
            name="password"
            render={({ field: { ref, value, ...rest }, fieldState }) => {
              const errors = ((fieldState.error ?? []) as Array<FieldError>).map(
                ({ message }) => message,
              );

              function isValid(key) {
                if (!errors.length) {
                  return Boolean(value);
                }

                return !errors.includes(key);
              }

              return (
                <>
                  <TextFieldPassword
                    label="Password"
                    required
                    fullWidth
                    inputRef={ref}
                    value={value}
                    {...rest}
                  />
                  <InputRules
                    label="Password must contain:"
                    rules={[
                      { message: 'At least 8 characters', isValid: isValid('min-length') },
                      { message: 'One uppercase letter', isValid: isValid('uppercase') },
                      { message: 'One number', isValid: isValid('one-digit') },
                      {
                        message: 'One special character',
                        isValid: isValid('special-character'),
                      },
                    ]}
                  />
                </>
              );
            }}
          />
        </Col>
        <Col xs={24}>
          <Button
            variant="contained"
            fullWidth
            disabled={!formState.isValid}
            loading={formState.isSubmitting}
            type="submit"
          >
            Sign up with Email
          </Button>
        </Col>
      </Row>
    </form>
  );
}
