import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import './Link.scss';

interface BaseProps {
  className?: string;
}

interface ExternalProps extends BaseProps, React.AnchorHTMLAttributes<HTMLAnchorElement> {}

interface InternalProps extends Omit<LinkProps, 'to'> {
  to?: string;
}

type Props = ExternalProps & InternalProps;

export default function Link({ className, to, href, children, ...rest }: Props) {
  const Compoment = !href ? RouterLink : ExternalLink;

  return (
    <Compoment className={clsx('Link', className)} {...(!href ? { to } : { href })} {...rest}>
      {children}
    </Compoment>
  );
}

function ExternalLink({ href, ...rest }: ExternalProps) {
  return <a href={href} target="_blank" rel="noopener noreferrer" {...rest} />;
}
