import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const Partners = lazy(() =>
  import(
    /* webpackChunkName: "overview" */
    'components/Partners/Partners'
  ),
);
const PartnerLanding = lazy(() =>
  import(
    /* webpackChunkName: "overview" */
    'components/Partners/PartnerLanding/PartnerLanding'
  ),
);
const OwnerRez = lazy(() =>
  import(
    /* webpackChunkName: "overview" */
    'components/Partners/PartnerCompanies/OwnerRez/OwnerRezIntegration'
  ),
);

const CreateStores = lazy(() =>
  import(
    /* webpackChunkName: "overview" */
    'components/Partners/PartnerCompanies/CreateStores'
  ),
);

const PartnerRoutes = () => {
  return (
    <Switch>
      <Route exact path="/overview/partners/" component={Partners} />
      <Route path="/overview/partners/landing/:company?" component={PartnerLanding} />
      <Route path="/overview/partners/createStores/" component={CreateStores} />

      <Route path="/overview/partners/:company?/">
        <Switch>
          <Route path="/overview/partners/ownerrez/" component={OwnerRez} />
        </Switch>
      </Route>
      <Route>
        <Redirect to="/overview/partners/" />
      </Route>
    </Switch>
  );
};

export default PartnerRoutes;
