import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import logoSvg from './logo.svg';

export default function TextLogo({ className, ...rest }) {
  return (
    <img
      className={classNames(className, 'TextLogo')}
      src={logoSvg}
      width={197}
      height={23}
      {...rest}
    />
  );
}

TextLogo.propTypes = {
  className: PropTypes.string,
};
