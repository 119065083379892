import React from 'react';
import { Divider } from 'antd';
import {
  Instagram as InstagramIcon,
  Facebook as FacebookIcon,
  Linkedin as LinkedinIcon,
} from 'react-feather';
import TextLogo from 'components/Brand/TextLogo';
import { Button, IconButton, Typography } from 'ui-kit';
import './Footer.scss';

const currentYear = new Date().getFullYear();

export default function Footer() {
  return (
    <footer className="Footer">
      <div className="Footer-container">
        <div className="Footer-container__content">
          <div className="Footer-container__content-socials">
            <TextLogo className="" />
            <div className="Footer_icons">
              <IconButton
                variant="text"
                color="textGray"
                href="https://www.instagram.com/thehostcompany/"
              >
                <InstagramIcon />
              </IconButton>
              <IconButton
                variant="text"
                color="textGray"
                href="https://www.facebook.com/TheHostCompany/"
              >
                <FacebookIcon />
              </IconButton>
              <IconButton
                variant="text"
                color="textGray"
                href="https://www.linkedin.com/company/the-host-co"
              >
                <LinkedinIcon />
              </IconButton>
            </div>
          </div>

          <div className="Footer-container__content-aboutUs">
            <Typography variant="subtitle1" className="Footer-container__content-aboutUs-title">
              About Us
            </Typography>
            <Typography variant="body2">
              The Host Co. is a retail solution designed to help. Hosts sell products in their short
              term rental spaces. Simply create a store, personalize it, and begin selling
              instantly.
            </Typography>
          </div>

          <div className="Footer-container__content-links">
            <Button
              href="https://thehostcohelp.zendesk.com/hc/en-us"
              target="_blank"
              color="textGray"
            >
              FAQ
            </Button>
            <Button href="https://www.thehost.co/guest-help-returns" color="textGray">
              Help + Returns
            </Button>
            <Button href="https://www.thehost.co/terms-conditions" color="textGray">
              Terms & Conditions
            </Button>
            <Button href="https://www.thehost.co/privacy-policy" color="textGray">
              Privacy Policy
            </Button>
            <Button href="mailto:support@thehost.co" color="textGray">
              Contact Us
            </Button>
          </div>
        </div>
        <div>
          <Divider />
          <Typography variant="body2" color="textGray">
            Copyright © {currentYear}
          </Typography>
        </div>
      </div>
    </footer>
  );
}
